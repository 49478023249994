export const NavData = [
    {
      title: "Home",
      path: "/",

    },
    // {
    //   title: "Portfolio",
    //   path: "portfolio",
    //
    // },
    // {
    //   title: "About",
    //   path: "about",
    //
    // },  {
    //   title: "Contact",
    //   path: "contact",
    //
    // }
  ]
