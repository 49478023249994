import React from 'react'

const Footer = () => {

  const year = new Date().getFullYear()

  return (
    <div className="text-center h-auto">
      <div className='text-white m-4'>
        <small>All Rights Reserved {year} by &copy; Brain<span className="text-red-500 font-bold">.Nine</span> and Communication & Media Concepts </small>
      </div>
    </div>
  )
}

export default Footer;
