import React from "react";
import Radio from "./Radio";
import Footer from './Footer';


const Hero = () => {
  return (
    <React.Fragment>
      <section>
        <div className='centered h-screen -mt-20'>
          <div>
            <div className="text-white opacity-15 sm:text-left">
              <p className='text-4xl md:text-6xl'> </p>
            </div>
            <div className=''>
              <Radio />
            </div>
            <div className="">
              <Footer />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Hero;
