exports.songsdata = [
    {
        "title": "Radio",
        "url": "https://s5.radio.co/s940f507d2/listen"
    },
    {
        // "title": "Drake - Forever",
        // "url": "https://beardbarnmusicbucket.s3.amazonaws.com/The+Wild+Horse"
    },
    {
        // "title": "Linking Park - In the end",
        // "url": "https://beardbarnmusicbucket.s3.amazonaws.com/The+Wild+Horse"
    },
]


// https://digistreamer.nl:8030/radio.mp3
