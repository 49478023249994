import React from 'react'
import Radio from '../components/Radio';
import Navbar from '../components/navbar/Navbar';
import Hero from '../components/Hero';
import Footer from '../components/Footer';

const Homepage = () => {


  return (
    <React.Fragment>
      <section>
      <div className='wrapper md:px-20 lg:px-40'>
        <Navbar />
        <Hero />
      
      </div>
      </section>
    </React.Fragment>
  );
}

export default Homepage;
