import { useRef, useState, useEffect } from 'react';
import Player from '../Player/Player';
import { songsdata } from '../Player/audios';

const Radio = () => {
  const [songs, setSongs] = useState(songsdata);
  const [isplaying, setisplaying] = useState(false);
  const [currentSong, setCurrentSong] = useState(songsdata[0]);
  const [volume, setVolume] = useState(30)
  const [muted, setMuted] = useState(false)

  const audioElem = useRef();

  useEffect(() => {
    if (isplaying) {
      audioElem.current.play();
    }
    else {
      audioElem.current.pause();
    }
  }, [isplaying, currentSong])

  useEffect(() => {
    if(audioElem){
      audioElem.current.volume = volume / 100;
    }
  }, [volume]);

  const onPlaying = () => {
    const duration = audioElem.current.duration;
    const ct = audioElem.current.currentTime;

    setCurrentSong({ ...currentSong, "progress": ct / duration * 100, "length": duration })

  }




  return (
    <div>
      <audio src={currentSong.url} ref={audioElem} onTimeUpdate={onPlaying} muted={muted} />
      <Player songs={songs} setSongs={setSongs} isplaying={isplaying} setisplaying={setisplaying} audioElem={audioElem} currentSong={currentSong} setCurrentSong={setCurrentSong} muted={muted} setMuted={setMuted} volume={volume} setVolume={setVolume} />

    </div>
  );
}

export default Radio;
