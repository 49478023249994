import React, { useRef, useState } from 'react';
import './player.scss';
import {BsFillPlayCircleFill, BsFillPauseCircleFill, BsFillSkipStartCircleFill, BsSkipEndCircleFill, BsFillSkipEndCircleFill, BsFillVolumeUpFill, BsFillVolumeMuteFill, BsFillVolumeOffFill, BsFillVolumeDownFill} from 'react-icons/bs';

const Player = ({audioElem, isplaying, setisplaying, currentSong, setCurrentSong, songs, muted, setMuted, volume, setVolume})=> {


  const clickRef = useRef();

  const PlayPause = ()=>
  {
    setisplaying(!isplaying);

  }

  let toggleMute = ()=> {
    setMuted(!muted);
  }


  const checkWidth = (e)=>
  {
    let width = clickRef.current.clientWidth;
    const offset = e.nativeEvent.offsetX;

    const divprogress = offset / width * 100;
    audioElem.current.currentTime = divprogress / 100 * currentSong.length;

  }

  const skipBack = ()=>
  {
    const index = songs.findIndex(x=>x.title == currentSong.title);
    if (index == 0)
    {
      setCurrentSong(songs[songs.length - 1])
    }
    else
    {
      setCurrentSong(songs[index - 1])
    }
    audioElem.current.currentTime = 0;

  }


  const skiptoNext = ()=>
  {
    const index = songs.findIndex(x=>x.title == currentSong.title);

    if (index == songs.length-1)
    {
      setCurrentSong(songs[0])
    }
    else
    {
      setCurrentSong(songs[index + 1])
    }
    audioElem.current.currentTime = 0;

  }

  const VolumeBtns = () => {
    muted ? <BsFillVolumeMuteFill onClick={toggleMute} className="btn_action" />
    : volume <= 20 ? <BsFillVolumeOffFill className="btn_action" onClick={toggleMute} />
    : volume <= 75 ? <BsFillVolumeDownFill className="btn_action" onClick={toggleMute} />
    : <BsFillVolumeUpFill className="btn_action" onClick={toggleMute} />
  }

  return (
    <div className='radio_container'>
    <div className='player_container'>
      <div className="title">
        <p>{currentSong.title}</p>
      </div>
      <div className="navigation">
        <div className="navigation_wrapper" onClick={checkWidth} ref={clickRef}>
          <div className="seek_bar" style={{width: `${currentSong.progress+"%"}`}}></div>
        </div>
      </div>
      <div className="controls">
        <BsFillSkipStartCircleFill className='btn_action' onClick={skipBack}/>
        {isplaying ? <BsFillPauseCircleFill className='btn_action pp' onClick={PlayPause}/> : <BsFillPlayCircleFill className='btn_action pp' onClick={PlayPause}/>}
        <BsFillSkipEndCircleFill className='btn_action' onClick={skiptoNext}/><br />
      </div>
      <div className="controls">
        {muted ? <BsFillVolumeMuteFill onClick={toggleMute} className="btn_action" />
        : volume == 0 ? <BsFillVolumeMuteFill className="btn_action" onClick={toggleMute} />
        : volume <= 20 ? <BsFillVolumeOffFill className="btn_action" onClick={toggleMute} />
        : volume <= 65 ? <BsFillVolumeDownFill className="btn_action" onClick={toggleMute} />
        : <BsFillVolumeUpFill className="btn_action" onClick={toggleMute} />}
        <input
          type="range"
          min={0}
          max={100}
          value={volume}
          onChange={event => {
          setVolume(event.target.valueAsNumber)
          }}
        />
      </div>
    </div>
    </div>

  )
}

export default Player
